import { toast } from "../../Components/Ui/Toast/Toast";
import { apiCalGetFile, apiCallGet, apiCallPost } from "../../Services/ApiClient";
import { CARD_USER_LIST_API, HYPERCARD_BALANCE_API, RAIN_CARD_TRANSECTION_HISTORY, LIMINAL_BALANCE_API, SUPPORTED_COIN_API, UPDATE_FEE_API, RAIN_CARD_LIST_API, UPDATE_STATUS_API, USER_CARD_DETAIL_API, USER_CARD_HISTORY_API, USER_SHIPPING_DETAILS_API, USER_RAIN_CARD_DETAIL_API } from "../../Services/EndPoints";
import commonError from "../../Components/Common/Index";

/************************************** getCardUserList ****************************************************/
export const getCardUserList = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(CARD_USER_LIST_API, data).then(response => {
        const res = response.data;
        // console.log('res getCardUserList::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('getCardUserList er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** getUserShippingDetail ****************************************************/
export const getUserShippingDetail = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(USER_SHIPPING_DETAILS_API, data).then(response => {
        const res = response.data;
        // console.log('res getUserShippingDetail::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('getUserShippingDetail er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** getUserCardDetail ****************************************************/
export const getUserCardDetail = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(USER_CARD_DETAIL_API, data).then(response => {
        const res = response.data;
        // console.log('res getUserCardDetail::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('getUserCardDetail er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};

/*****************************************RaincardDetails****************************** */
export const getRainCardDetail = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(USER_RAIN_CARD_DETAIL_API, data).then(response => {
        const res = response.data;
        console.log('res getRainCardDetail>>>>>>>>>>>>>>>::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('getRainCardDetail er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/****************************************Raincardtransection history************************/

export const RaincardHistory = ({ userId, trxnType = '', description = '' }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallGet(`${RAIN_CARD_TRANSECTION_HISTORY}?userId=${userId}&trnxType=${trxnType}&description=${description}`).then(response => {
        const res = response.data;
        console.log('response>>>>>>>>>>>>::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('RaincardHistory er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};

/************************************** getUserCardHistory ****************************************************/
export const getUserCardHistory = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(USER_CARD_HISTORY_API, data).then(response => {
        const res = response.data;
        // console.log('res getUserCardHistory::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('getUserCardHistory er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** getSupportedCoins ****************************************************/
export const getSupportedCoins = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallGet(SUPPORTED_COIN_API).then(response => {
        const res = response.data;
        // console.log('res getSupportedCoins::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('getSupportedCoins er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** updateCoinStatus ****************************************************/
export const updateCoinStatus = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(UPDATE_STATUS_API, data).then(response => {
        const res = response.data;
        // console.log('res updateCoinStatus::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('updateCoinStatus er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** updateFee ****************************************************/
export const updateFee = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(UPDATE_FEE_API, data).then(response => {
        const res = response.data;
        // console.log('res updateFee::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('updateFee er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** getHyperCardBal ****************************************************/
export const getHyperCardBal = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallGet(HYPERCARD_BALANCE_API).then(response => {
        const res = response.data;
        // console.log('res getHyperCardBal::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('getHyperCardBal er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** getLiminalBal ****************************************************/
export const getLiminalBal = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallGet(LIMINAL_BALANCE_API).then(response => {
        const res = response.data;
        //  console.log('res getLiminalBal::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('getLiminalBal er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/*********************************************raincardhistory*********************** */
export const getRaincardUsers = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(RAIN_CARD_LIST_API, data).then(response => {
        const res = response.data;
        // console.log('res getCardUserList::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('getRaincardUsers er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};


/************************************** downloadCSV ****************************************************/
export const downloadCSV = ({ link }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCalGetFile(link).then(response => {
        const res = response;
        //  console.log('res downloadCSV::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('downloadCSV er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
