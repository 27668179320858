import React from "react";
import "./Cards.scss";
function Cards(props) {
  const {
    status = "Inactive",
    number = "****************",
    kycstatus,
    feepaid,
    cardstatus,
    availableBalance,
    shippingad,
    isRain,
    loading_fee,
    maintenance_fee,
    country
  } = props;
  return (
    <>
      <div className="cards">
        <div className="cards_vertual">
          <h5 className="" style={{ textTransform: "capitalize" }}>Status : {status}</h5>
          <p>{number}</p>
        </div>
        {number == "****************" ? null : (
          <div className="cards_detail">
            <ul>
              {isRain &&
                <>
                  <li>
                    <p>Country</p>
                    <p style={{ textTransform: "capitalize" }}>{country}</p>
                  </li>
                  <li>
                    <p>Card Issuance Fee</p>
                    <p style={{ textTransform: "uppercase" }}>{feepaid} USD</p>
                  </li>
                  <li>
                    <p>Monthly Management Fee</p>
                    <p style={{ textTransform: "uppercase" }}>{maintenance_fee} USD</p>
                  </li>
                  <li>
                    <p>Loading Fee</p>
                    <p style={{ textTransform: "uppercase" }}>{loading_fee}%</p>
                  </li>
                </>
              }

              {/* <li>
                <p>Fees Paid</p>
                <p style={{ textTransform: "uppercase" }}>{feepaid}</p>
              </li> */}
              <li>
                <p>{isRain ? 'Spending Power' : 'Card Balance'}</p>
                {isRain ? <p style={{ textTransform: "uppercase" }}>{availableBalance >= 0 ? `${availableBalance} USD` : `-${Math.abs(availableBalance)} USD`}</p>
                  : <p style={{ textTransform: "uppercase" }}>{availableBalance}</p>}
              </li>
              <li>
                <p>Card Status</p>
                <p style={{ textTransform: "capitalize" }}>{cardstatus}</p>
              </li>
              {kycstatus ? (
                <li>
                  <p>Kyc Status</p>
                  <p>{kycstatus}</p>
                </li>
              ) : (
                false
              )}
              {shippingad ? (
                <li>
                  <p>shipping Address</p>
                  <p>{shippingad}</p>
                </li>
              ) : (
                false
              )}
            </ul>
          </div>
        )}
      </div>
    </>
  );
}

export default Cards;
