import { Breadcrumb, Button, Col, DatePicker, Row, Space, Upload } from "antd";
import React, { useEffect, useState } from "react";
import "./Proposal.scss";
import InputCustom from "../../../Ui/input/InputCustom";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import { ButtonCustom } from "../../../Ui/button/ButtonCustom";
import CkEdior from "../../../Ui/CkEdior/CkEdior";
import CustomSelect from "../../../Ui/CustomSelect/CustomSelect";
import { useDispatch } from "react-redux";
import {
  addProposal,
  deleteProposal,
  editAfterProposal,
  editProposal,
  getAssetsListDropdown,
  uploadProposalFileData,
} from "../../../../redux/_actions/assetAction";
import Swal from "sweetalert2";
import { toast } from "../../../Ui/Toast/Toast";
import moment from "moment";
import CrossIcon from "../../../Assets/Images/CrossIcon.svg";
import { addProposalMethod } from "../../../../Services/web3/ContractServices";
import Loader from "../../../../Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import {
  MAX_OPTIONS_LENGTH,
  MAX_OPTIONS_LENGTH_EXCEEDED,
} from "../../../constant/constant";

function EditProposalPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useNavigate hook
  const location = useLocation();
  const proposalData = location.state?.proposalData;
  console.log("PROPOSAL DATA", proposalData);
  // const [head, setHead] = useState({
  //   head: "",
  //   subhead: [{ sub_head_name: "", sub_head_value: "", icon: "" }],
  // });
  const initialHead = {
    head: proposalData?.proposal_icon ? "Dynamic Head Value" : "",
    subhead: proposalData?.proposal_icon
      ? proposalData?.proposal_icon.map((iconData) => ({
          sub_head_name: iconData.sub_head_name,
          sub_head_value: iconData.sub_head_value,
          icon: iconData.icon,
        }))
      : [],
  };
  const [head, setHead] = useState(initialHead);

  const [subheadErrors, setSubheadErrors] = useState([]);
  console.log("subheadErrors", subheadErrors);
  const handleSubheadValidation = () => {
    const errors = head.subhead.map((subhead, index) => {
      const error = {};

      if (subhead.sub_head_name.trim() === "") {
        error.sub_head_name = "Sub Head Name is required";
      }

      if (subhead.sub_head_value.trim() === "") {
        error.sub_head_value = "Sub Head Value is required";
      }

      if (subhead.icon === null || subhead.icon === "") {
        error.icon = "Icon is required";
      }

      return {
        index,
        error,
      };
    });

    setSubheadErrors(errors);
    return errors.every(({ error }) => Object.keys(error).length === 0);
  };

  const initilaObj = {
    title: proposalData?.title,
    description: proposalData?.description,
    company_name: proposalData?.company_name,
    token_name: proposalData?.token_name,
    symbol: proposalData?.symbol,

    raise_fund: proposalData?.raise_fund,
    fee: proposalData?.fee,
    min_investment: proposalData?.min_investment,
    token_quantity: proposalData?.token_quantity,
    project_yield: proposalData?.project_yield,
    asset_type_id: proposalData?.asset_type_id,
    start_date: proposalData?.start_date,
    end_date: proposalData?.end_date,
    asset_info: proposalData?.asset_info,
    head: proposalData?.head,
    // sub_head: "",
    error: {
      title: "",
      description: "",
      company_name: "",
      token_name: "",
      symbol: "",

      raise_fund: "",
      fee: "",
      min_investment: "",
      token_quantity: "",
      project_yield: "",
      asset_type_id: "",
      start_date: "",
      end_date: "",
      asset_info: "",
      banner_image: "",
      document: "",
    },
  };
  const [allState, setAllState] = useState(initilaObj);
  const [isLoading, setLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [documentData, setDocumentData] = useState("");
  const [bannerError, setBannerError] = useState("");
  const [documentError, setDocumentError] = useState("");
  useEffect(() => {
    fetchAssetsList();
    setInitialBannerData();
  }, []);

  const fetchAssetsList = () => {
    setTimeout(() => {
      dispatch(getAssetsListDropdown({}))
        .then((response) => {
          setAssets(response?.rows);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
        });
    }, 100);
  };

  const handleValidaton = (data) => {
    var error = allState?.error;
    const decimalRegex = /^\d*\.?\d*$/; // Regular expression to match decimal numbers
    const positiveIntegerRegex = /^\d+$/; // Regular expression to match positive integers
    var isValidate = true;
    for (let [key, value] of Object.entries(data)) {
      switch (key) {
        case "title":
          if (value === undefined || value?.length === 0) {
            error[key] = "Title Field Is Required";
            isValidate = false;
          } else {
            error[key] = "";
          }
          break;
        case "description":
          if (value === undefined || value?.length === 0) {
            error[key] = "Description Field Is Required";
            isValidate = false;
          } else {
            error[key] = "";
          }
          break;
        case "company_name":
          if (value === undefined || value?.length === 0) {
            error[key] = "Company Name Field Is Required";
            isValidate = false;
          } else {
            error[key] = "";
          }
          break;
        case "token_name":
          if (value === undefined || value?.length === 0) {
            error[key] = "Token Name Field Is Required";
            isValidate = false;
          } else {
            error[key] = "";
          }
          break;
        case "symbol":
          if (value === undefined || value?.length === 0) {
            error[key] = "Token Symbol Field Is Required";
            isValidate = false;
          } else {
            error[key] = "";
          }
          break;
        case "raise_fund":
          if (value === undefined || value?.length === 0) {
            error[key] = "Raise Fund Field Is Required";
            isValidate = false;
          } else if (!positiveIntegerRegex.test(value)) {
            error[key] = "Raise fund can only be in whole numbers";
            isValidate = false;
          } else {
            error[key] = "";
          }
          break;
        case "fee":
          if (value === undefined || value?.length === 0) {
            error[key] = "Fee Field Is Required";
            isValidate = false;
          } else if (!positiveIntegerRegex.test(value)) {
            error[key] = "Fee can only be in whole numbers";
            isValidate = false;
          } else {
            error[key] = "";
          }
          break;
        case "min_investment":
          if (value === undefined || value?.length === 0) {
            error[key] = "Min Investment Field Is Required";
            isValidate = false;
          } else if (!positiveIntegerRegex.test(value)) {
            error[key] = "Min Investment can only be in whole numbers";
            isValidate = false;
          } else {
            error[key] = "";
          }
          break;
        case "token_quantity":
          if (value === undefined || value?.length === 0) {
            error[key] = "Token Quantity Field Is Required";
            isValidate = false;
          } else if (!positiveIntegerRegex.test(value)) {
            error[key] = "Token Quantity can only be in whole numbers";
            isValidate = false;
          } else {
            error[key] = "";
          }
          break;
        // case "project_yield":
        //   if (value === undefined || value?.length === 0) {
        //     error[key] = "Project Yield Field Is Required";
        //     isValidate = false;
        //   } else {
        //     error[key] = "";
        //   }
        //   break;
        case "start_date":
          if (value === undefined || value?.length === 0) {
            error[key] = "Start Date Field Is Required";
            isValidate = false;
          } else {
            error[key] = "";
          }
          break;
        case "end_date":
          if (value === undefined || value?.length === 0) {
            error[key] = "End Date Field Is Required";
            isValidate = false;
          } else {
            error[key] = "";
          }
          break;
        case "asset_info":
          if (value === undefined || value?.length === 0) {
            error[key] = "Asset Info Field Is Required";
            isValidate = false;
          } else {
            error[key] = "";
          }
          break;
        case "asset_type_id":
          if (value === undefined || value?.length === 0) {
            error[key] = "Asset Type Field Is Required";
            isValidate = false;
          } else {
            error[key] = "";
          }
          break;
        case "banner_image":
          if (value === undefined || value?.length === 0) {
            error[key] = "Banner Image Field Is Required";
            isValidate = false;
          } else {
            error[key] = "";
          }
          break;
        case "document":
          if (value === undefined || value?.length === 0) {
            error[key] = "Document Field Is Required";
            isValidate = false;
          } else {
            error[key] = "";
          }
          break;

        default:
      }
    }
    setAllState({ ...allState, error: { ...error } });
    return isValidate;
  };

  const disabledStartDate = (current) => {
    // Disable past dates and dates after the selected end date
    const isBeforeToday = current && current < moment().startOf("day");
    const isAfterEndDate =
      allState.end_date &&
      current &&
      current > moment(allState.end_date).endOf("day");
    return isBeforeToday || isAfterEndDate;
  };

  const disabledEndDate = (current) => {
    // Disable past dates and dates before the selected start date
    const isBeforeToday = current && current < moment().startOf("day");
    const isBeforeStartDate =
      allState.start_date &&
      current &&
      current < moment(allState.start_date).startOf("day");
    return isBeforeToday || isBeforeStartDate;
  };

  const disabledStartDateTime = (current) => {
    if (!current) return null; // Enable all times if no date is selected

    const currentMoment = moment();

    // If start date is chosen and it's today
    if (current.isSame(currentMoment, "day")) {
      return {
        disabledHours: () => [...Array(currentMoment.hour()).keys()],
        disabledMinutes: () =>
          currentMoment.hour() === current.hour()
            ? [...Array(currentMoment.minute()).keys()] // Disable previous minutes only if it's the current hour
            : [],
      };
    }

    // If end date is chosen and it's today
    if (allState.end_date) {
      const endDateTime = moment(allState.end_date);
      if (endDateTime.isValid() && endDateTime.isSame(currentMoment, "day")) {
        return {
          disabledHours: () => [...Array(endDateTime.hour() + 1).keys()],
          disabledMinutes: (hour) => {
            if (hour === endDateTime.hour()) {
              return [...Array(endDateTime.minute()).keys()];
            }
            return [];
          },
        };
      }
    }

    // If date is after today, enable all times
    if (current.isAfter(currentMoment, "day")) {
      return null;
    }

    // If date is before today, disable all times
    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
    };
  };

  const disabledEndDateTime = (current) => {
    if (!current) return null; // Enable all times if no date is selected

    const startDateTime = moment(allState.start_date);
    const currentMoment = moment();

    // If start date is chosen and it's after today
    if (startDateTime.isAfter(currentMoment, "day")) {
      return null; // Enable all times
    }

    // If start date is chosen and it's before today or today
    return {
      disabledHours: () => {
        if (startDateTime.isSame(current, "day")) {
          // If start date and current date are the same, disable previous hours
          return [...Array(startDateTime.hour()).keys()];
        } else {
          // Otherwise, enable all hours
          return [];
        }
      },
      disabledMinutes: (hour) => {
        if (
          startDateTime.isSame(current, "day") &&
          hour === startDateTime.hour()
        ) {
          // Disable previous minutes if start date and current date are the same and hour is the same
          return [...Array(startDateTime.minute()).keys()];
        } else {
          // Otherwise, enable all minutes
          return [];
        }
      },
      // Clear time if current date is changed to present date with time after present time
      ...(startDateTime.isSame(current, "day") &&
      current.isSame(currentMoment, "day") &&
      current.isAfter(currentMoment)
        ? { disabled: () => [59] } // Disable all times
        : {}),
    };
  };

  const isStartDateValid = () => {
    console.log("INNNNNNNNNNN THE CHECK");
    const startDateTimestamp = moment.utc(allState.start_date).unix();
    const presentTimestamp = moment.utc().unix();
    console.log("startDateTimestamp", startDateTimestamp);
    console.log("presentTimestamp", presentTimestamp);

    return startDateTimestamp > presentTimestamp;
  };
  const setValue = (event) => {
    if (event.target.value !== "") {
      var error = allState?.error;
      error[event.target.name] = "";
      setAllState({ ...allState, error: { ...error } });
    }
    setAllState({ ...allState, [event.target.name]: event.target.value });
  };

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const setInitialBannerData = () => {
    if (proposalData?.proposal_file && proposalData.proposal_file.length > 0) {
      // Extract all image URLs from proposalData.proposal_file
      const bannerImageUrls = proposalData.proposal_file.map(
        (file) => file.url
      );
      // Set initial banner image data with URLs
      setImageData(bannerImageUrls.map((url) => ({ url })));
    }
  };

  console.log(moment(allState.start_date).unix());
  const saveProposal = async () => {
    const isValidSubhead = handleSubheadValidation();
    const start_date_utc = moment(allState.start_date)
      .utc()
      .format("YYYY-MM-DD HH:mm");
    const end_date_utc = moment(allState.end_date)
      .utc()
      .format("YYYY-MM-DD HH:mm");
    let data = {
      title: allState.title,
      description: allState.description,
      company_name: allState.company_name,
      token_name: allState.token_name,
      symbol: allState.symbol,
      head: allState.head,

      raise_fund: allState.raise_fund,
      fee: allState.fee,
      min_investment: allState.min_investment,
      token_quantity: allState.token_quantity,
      project_yield: allState.project_yield,
      start_date: start_date_utc,
      end_date: end_date_utc,
      asset_info: allState.asset_info,
      asset_type_id: allState.asset_type_id,
      banner_image: imageData,
      token_value: 0,
      sub_head: head?.subhead,
      edit_id: proposalData?.id,

      // document: documentData
    };
    let isValid = handleValidaton(data);

    console.log("DATA AFTER EDITING", data);

    if (isValid && isValidSubhead) {
      if (data.raise_fund % data.token_quantity !== 0) {
        toast.error(
          "Raised fund divided by token quantity should result in a whole number"
        );
        return (isValid = false);
      } else {
      }

      setLoading(true);
      try {
        setLoading(true);

        // Call addProposal API
        const addProposalResponse = await dispatch(addProposal({ data }));
        console.log("EDITPROPOSALRESPONSE", addProposalResponse);

        // Check if data is present in the response
        if (addProposalResponse) {
          // Call addProposalMethod with the received data
          const result = await addProposalMethod(data, addProposalResponse);

          if (result) {
            const { salt, token, project_id } = result;
            if (salt && token && project_id) {
              let data = {
                unique_id: salt,
                token_address: token,
                project_id: project_id,
              };
              console.log("DATATTATATA", data);

              const editProposalResponse = await dispatch(
                editProposal({ data })
              );

              // Show success message
              Swal.fire({
                icon: "success",
                title: "Success!",
                confirmButtonColor: "#d3d3d3",
                text: "Proposal Edited successfully!",
                color: "#7D80DF",
              }).then((result) => {
                handleDeleteProposal(proposalData?.id);
                // Navigate to '/assetIssuance' after the Swal is closed
                navigate("/assetIssuance");
              });
            } else {
              console.log("IN ELSE CONDITION");
              let data = {
                unique_id: addProposalResponse,
                token_address: null,
                project_id: null,
              };
              console.log("DATATTATATA", data);

              const editProposalResponse = await dispatch(
                editProposal({ data })
              );

              // Show success message
              Swal.fire({
                icon: "error",
                title: "Error!",
                confirmButtonColor: "#d3d3d3",
                text: "Proposal Not Edited!",
                color: "#7D80DF",
              }).then((result) => {
                // Navigate to '/assetIssuance' after the Swal is closed
                navigate("/assetIssuance");
              });
            }
          } else {
            // Handle the case where result is null
            // This might happen if there is an error in addProposalMethod
            // Your existing code...
          }

          // Reset state and clear data
          setAllState(initilaObj);
          setImageData([]);
        } else {
          setLoading(false);

          console.error("Error in addProposal API: No data received");
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);

        // Handle any errors during addProposal API or addProposalMethod
        toast.error(err);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);

      console.log("Failed");
    }
  };
  const editorHandler = (data) => {
    var error = allState?.error;
    error["description"] = "";
    setAllState({ ...allState, error: { ...error } });
    setAllState((prevState) => ({
      ...prevState,
      description: data.trimStart(),
    }));
  };
  const editorHandlerA = (data) => {
    var error = allState?.error;
    error["asset_info"] = "";
    setAllState({ ...allState, error: { ...error } });
    setAllState((prevState) => ({
      ...prevState,
      asset_info: data.trimStart(),
    }));
  };
  const uploadFile = (e) => {
    setLoading(true);
    const formData = new FormData();
    const imagedata = e.target.files[0];
    // console.log('chk image data:::::::', imagedata)
    formData.append("banner_image", imagedata);
    formData.append("type", "Image");
    // formData.append('files', { name: imagedata.name, type: imagedata.type });
    // console.log('formData1111 data:::::::', formData)
    dispatch(uploadProposalFileData({ formData }))
      .then((res) => {
        let _imageData = [...imageData];
        _imageData.push(res[0]);
        setImageData(_imageData);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  };

  const handleIconUpload = (e, index) => {
    setLoading(true);
    const formData = new FormData();
    const imagedata = e.target.files[0];
    formData.append("icon", imagedata);
    formData.append("type", "Icon");

    dispatch(uploadProposalFileData({ formData }))
      .then((res) => {
        let updatedSubhead = [...head.subhead];
        updatedSubhead[index].icon = res[0].url; // Assuming response contains iconUrl

        setHead({
          ...head,
          subhead: updatedSubhead,
        });

        let updatedSubheadErrors = [...subheadErrors];

        if (index < updatedSubheadErrors.length) {
          updatedSubheadErrors[index].error.icon = "";
        }
        setSubheadErrors(updatedSubheadErrors);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  };

  function renderSubheads() {
    return (
      <>
        {console.log("subhead", head.subhead)}
        {Array.isArray(head?.subhead) &&
          head?.subhead.map((subhead, index) => (
            <div className="selectores" key={index}>
              <Row gutter={[12, 12]} className="propsalInnerInput">
                <Col xs={24} md={12} lg={6}>
                  <InputCustom
                    label="Add subhead"
                    inputCustum
                    basicInput
                    placeholder={"Subhead Title"}
                    value={subhead.sub_head_name}
                    onChange={(e) => {
                      let updatedSubhead = [...head.subhead];
                      updatedSubhead[index].sub_head_name = e.target.value;
                      setHead({
                        ...head,
                        subhead: updatedSubhead,
                      });
                    }}
                  />
                  {/* {subheadErrors[index]?.sub_head_name && (
                    <p className="error-msg">
                      {subheadErrors[index]?.error?.sub_head_name}
                    </p>
                  )} */}
                  <span className="error">
                    {subheadErrors[index]?.error?.sub_head_name}
                  </span>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <InputCustom
                    label="Add Value"
                    inputCustum
                    basicInput
                    placeholder={"Value"}
                    value={subhead.sub_head_value}
                    onChange={(e) => {
                      let updatedSubhead = [...head.subhead];
                      updatedSubhead[index].sub_head_value = e.target.value;
                      setHead({
                        ...head,
                        subhead: updatedSubhead,
                      });
                    }}
                  />
                  <span className="error">
                    {subheadErrors[index]?.error?.sub_head_value}
                  </span>
                </Col>
                <Col xs={24} md={12} lg={6} className="uploadpropsal">
                  <div className="inputContact selectSec">
                    <input
                      name={`icon_image_${index}`}
                      type="file"
                      className="form-control-file"
                      id={`icon_image_${index}`}
                      multiple
                      accept="image/png, image/jpeg"
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                      onChange={async (event) => {
                        let img = validateImage(event);
                        if (img) {
                          handleIconUpload(event, index);
                        }
                      }}
                    />
                  </div>
                  {subhead?.icon && (
                    <div className="galleryView">
                      <Row>
                        <Col className="galleryCol mt-2" xs={4} sm={2}>
                          <div className="crossIconRel">
                            <img
                              className="deleteDocImage"
                              onClick={() => removeIcon(index)}
                              src={CrossIcon}
                              alt="Delete"
                            />
                            <img
                              src={subhead.icon}
                              width={200}
                              height={115}
                              alt="Icon"
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                  <span className="error errorItem">
                    {subheadErrors[index]?.error?.icon}
                  </span>
                </Col>
                <Col xs={24} md={12} lg={4} className="deleteOption">
                  <ButtonCustom
                    onClick={() => {
                      let updatedSubheads = [...head.subhead];
                      updatedSubheads.splice(index, 1);
                      setHead({
                        ...head,
                        subhead: updatedSubheads,
                      });
                    }}
                    label="Remove"
                    yellowBtn
                  />
                </Col>
              </Row>
            </div>
          ))}
      </>
    );
  }
  const validateImage = (event) => {
    // console.log("RRRRRRRRRRR : ", event?.target?.value?.length);
    const image = event.target.files[0];
    if (!image) {
      setBannerError("Banner name is required");
      return false;
    } else if (!image.name.toLowerCase().match(/\.(jpg|jpeg|png)$/)) {
      setBannerError("Image should be JPG,JPEG,PNG format");
      return false;
    } else if (image.size > 5000000) {
      setBannerError("Image size should be less than 5MB");
      return false;
    } else if (image.name.length > 50) {
      setBannerError("Image name should be less than 50 character");
      return false;
    } else {
      setBannerError("");
      return true;
    }
  };

  const removeBanner = (index) => {
    let data = [...imageData];
    data.splice(index, 1);
    setImageData(data);
  };

  const removeIcon = (index) => {
    let updatedSubhead = [...head.subhead];
    updatedSubhead[index].icon = null; // Set the icon to null for the corresponding subhead
    setHead({
      ...head,
      subhead: updatedSubhead,
    });
  };

  const handleDeleteProposal = (id) => {
    console.log(id);

    // Proceed with deletion if user confirms
    // Prepare data for deletion
    const data = {
      proposal_id: id,
    };

    // Send deletion request
    setTimeout(() => {
      dispatch(deleteProposal({ data })).then((response) => {
        console.log("response in deleteproposal dispatch function", response);
        // Handle success
        // if (response && response.status === true) {
        //   console.log("IN IF CONDITION");
        //   // Handle success
        //   Swal.fire("Deleted!", "Your proposal has been deleted.", "success");
        //   // Optionally, you may want to update the UI after deletion
        //   // fetchProposalList();
        //   setLoading(false);
        // } else {
        //   console.log("IN ELSE CONDITION");

        //   // Handle deletion failure
        //   Swal.fire("Error!", "Failed to delete the proposal.", "error");
        //   setLoading(false);
        // }
      });
    }, 100);
  };

  return (
    <div className="proposal">
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item>
            {isStartDateValid() ? "Edit Proposal" : "View proposal details"}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="commonPadding">
        <div className="proposal_headTitle">
          <InputCustom
            basicInput
            placeholder="Add Title"
            label="Add Title"
            value={allState.title}
            name="title"
            onChange={(e) => {
              setValue(e);
            }}
          />
        </div>
        <span className="error">{allState.error.title}</span>
        <div>
          <CkEdior
            title={"Add Description"}
            editorHandler={editorHandler}
            additionalFeature={allState?.description}
          />
        </div>
        <span className="error">{allState.error.description}</span>
        <div className="proposal_addToken">
          <InputCustom
            basicInput
            placeholder="Add Company Name"
            label="Add Company Name"
            value={allState.company_name}
            name="company_name"
            onChange={(e) => {
              setValue(e);
            }}
          />
          <span className="error">{allState.error.company_name}</span>
          <Row gutter={[12, 12]}>
            <Col xs={24} md={12} lg={8}>
              <InputCustom
                basicInput
                placeholder="Add Token Name"
                label="Token Name"
                value={allState.token_name}
                name="token_name"
                onChange={(e) => {
                  setValue(e);
                }}
              />
              <span className="error">{allState.error.token_name}</span>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <InputCustom
                basicInput
                placeholder="Add Token Symbol"
                label="Token Symbol"
                value={allState.symbol}
                name="symbol"
                onChange={(e) => {
                  setValue(e);
                }}
              />
              <span className="error">{allState.error.symbol}</span>
            </Col>
          </Row>
          <div className="addBanner">
            <p>Add Banner</p>

            <div className="inputContact selectSec">
              <input
                name="banner_image"
                type="file"
                className="form-control-file"
                id="banner_image"
                multiple
                accept="image/png, image/jpeg"
                onClick={(event) => {
                  event.target.value = null;
                }}
                onChange={async (event) => {
                  let img = validateImage(event);
                  if (img) {
                    uploadFile(event);
                    setAllState({
                      ...allState,
                      error: { ...allState.error, banner_image: "" },
                    });
                  }
                }}
              />
            </div>
            <span className="error">{allState.error.banner_image}</span>
            <span className="error">{bannerError}</span>
            {Array.isArray(imageData) && imageData?.length > 0 ? (
              <div className="galleryView marginTop20">
                <Row>
                  {Array.isArray(imageData) &&
                    imageData.map((data, index) => (
                      <Col
                        className="galleyCol mt-2"
                        xs={24}
                        sm={12}
                        md={8}
                        lg={6}
                        xl={4}
                        // lg={1}
                        key={index}
                      >
                        <div className="crossIconRel">
                          <img
                            className="deleteDocImage"
                            onClick={async () => {
                              if (data) {
                                removeBanner(index);
                              }
                            }}
                            src={CrossIcon}
                          />

                          {typeof data === "object" ? (
                            <img src={data?.url} width={200} height={115} />
                          ) : (
                            <img src={data} width={200} height={115} />
                          )}
                          {/* <p> {data?.type}</p> */}
                        </div>
                      </Col>
                    ))}
                </Row>
              </div>
            ) : null}
          </div>
        </div>
        <Row gutter={[12, 12]}>
          <Col xs={24} md={12} lg={8}>
            <InputCustom
              basicInput
              placeholder="Raised Fund"
              label="Raised Fund (TUT Amount)"
              type={"number"}
              value={allState.raise_fund}
              name="raise_fund"
              onChange={(e) => {
                setValue(e);
              }}
            />
            <span className="error">{allState.error.raise_fund}</span>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <InputCustom
              basicInput
              placeholder="Add Fee"
              label="Fee"
              type={"number"}
              value={allState.fee}
              name="fee"
              onChange={(e) => {
                setValue(e);
              }}
            />
            <span className="error">{allState.error.fee}</span>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <InputCustom
              basicInput
              placeholder="Add Min Investment"
              label="Min Investment"
              type={"number"}
              value={allState.min_investment}
              name="min_investment"
              onChange={(e) => {
                setValue(e);
              }}
            />
            <span className="error">{allState.error.min_investment}</span>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <InputCustom
              basicInput
              placeholder="Add Token Quantity"
              label="Token Quantity (No. of TST Tokens)"
              type={"number"}
              value={allState.token_quantity}
              name="token_quantity"
              onChange={(e) => {
                setValue(e);
              }}
            />
            <span className="error">{allState.error.token_quantity}</span>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <InputCustom
              basicInput
              placeholder="Add Yield value"
              label="Projected Yield"
              type={"number"}
              value={allState.project_yield}
              name="project_yield"
              onChange={(e) => {
                setValue(e);
              }}
            />
            <span className="error">{allState.error.project_yield}</span>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <CustomSelect
              labelcustom="labelcustom"
              CustomSelectStyle="CustomSelectStyle"
              label={"Assign Assets"}
              placeholder="Select"
              data={assets}
              defaultData={allState?.asset_type_id}
              onChange={(e) => {
                var error = allState?.error;
                error["asset_type_id"] = "";
                setAllState({ ...allState, error: { ...error } });
                setAllState((prevState) => ({
                  ...prevState,
                  asset_type_id: e,
                }));
              }}
            />
            <span className="error">{allState.error.asset_type_id}</span>
          </Col>
        </Row>
        <Row gutter={[12, 12]} style={{ marginTop: "20px" }}>
          <Col xs={24} md={12} lg={8}>
            <p>Start Date</p>
            <Space direction="vertical">
              <DatePicker
                name="start_date"
                showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD HH:mm"
                disabledDate={disabledStartDate}
                disabledTime={disabledStartDateTime}
                value={
                  allState.start_date !== "" ? moment(allState.start_date) : ""
                }
                onChange={(date, dateString) => {
                  const error = allState?.error;
                  error["start_date"] = "";
                  setAllState({ ...allState, error: { ...error } });
                  setAllState({ ...allState, ["start_date"]: dateString });

                  if (!date) {
                    // If date is cleared, also clear the time
                    setAllState({ ...allState, ["start_date"]: "" });
                  } else {
                    // If the selected date is today, clear previous time
                    const currentMoment = moment();
                    if (date.isSame(currentMoment, "day")) {
                      const currentHour = currentMoment.hour();
                      const currentMinute = currentMoment.minute();
                      const selectedHour = date.hour();
                      const selectedMinute = date.minute();
                      if (
                        selectedHour < currentHour ||
                        (selectedHour === currentHour &&
                          selectedMinute < currentMinute)
                      ) {
                        // If selected time is before current time, clear it
                        setAllState({ ...allState, ["start_date"]: "" });
                      }
                    }
                  }

                  // If end date is already chosen and start date is changed, empty end date
                  if (
                    allState.end_date !== "" &&
                    date &&
                    moment(allState.end_date).isBefore(date)
                  ) {
                    setAllState({ ...allState, ["end_date"]: "" });
                  }
                }}
              />
              <span className="error">{allState.error.start_date}</span>
            </Space>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <p>End Date</p>
            <Space direction="vertical">
              <DatePicker
                name="end_date"
                disabled={!allState.start_date} // Disable DatePicker input if start date is not selected
                disabledDate={disabledEndDate} // Disable past dates
                disabledTime={disabledEndDateTime} // Disable past times
                showTime={{ format: "HH:mm" }} // Specify the time format
                format="YYYY-MM-DD HH:mm" // Specify the display format for date and time
                value={
                  allState.end_date !== "" ? moment(allState.end_date) : ""
                }
                onChange={(date, dateString) => {
                  var error = allState?.error;
                  error["end_date"] = "";
                  setAllState({ ...allState, error: { ...error } });

                  // Clear time if selected date time is before the start date time
                  const startDateTime = moment(
                    allState.start_date,
                    "YYYY-MM-DD HH:mm"
                  );
                  const selectedDateTime = moment(
                    dateString,
                    "YYYY-MM-DD HH:mm"
                  );
                  if (
                    startDateTime.isValid() &&
                    selectedDateTime.isBefore(startDateTime)
                  ) {
                    dateString = ""; // Clear end date value
                  }

                  setAllState({ ...allState, ["end_date"]: dateString });
                }}
              />
              <span className="error">{allState.error.end_date}</span>
            </Space>
          </Col>
        </Row>
        <div className="head">
          <InputCustom
            basicInput
            placeholder="Add head"
            label="Head name"
            value={allState.head}
            name="head"
            onChange={(e) => {
              setValue(e);
            }}
          />
          <span className="error">{allState.error.head}</span>{" "}
        </div>
        <div className="textleftOuter">
          <span className="errTxt">{}</span>
        </div>
        {renderSubheads()}
        <div className="addButton">
          <ButtonCustom
            onClick={() => {
              if (head.subhead.length >= MAX_OPTIONS_LENGTH) {
                toast.error(MAX_OPTIONS_LENGTH_EXCEEDED);
                return;
              }
              let arr = [...head.subhead];
              arr.push({
                sub_head_name: "",
                // id: head.subhead.length + 1,
                sub_head_value: "",
                icon: "",
              });
              setHead({
                ...head,
                subhead: arr,
              });
            }}
            yellowBtn
            label="Add Subhead +"
          >
            {/* <h2>Add Subhead +</h2> */}
          </ButtonCustom>
        </div>
        <div>
          <CkEdior
            title={"Asset Information"}
            editorHandler={editorHandlerA}
            additionalFeature={allState?.asset_info}
          />
        </div>
        <span className="error">{allState.error.asset_info}</span>
        {isStartDateValid() ? (
          <div className="addButton">
            <ButtonCustom
              label="Update"
              yellowBtn
              onClick={() => {
                saveProposal();
              }}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default EditProposalPage;
