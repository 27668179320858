import { Breadcrumb, Table } from "antd";
import React, { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Loader/Loader";
import { SearchOutlined } from "@ant-design/icons";
import { toast } from "../../Ui/Toast/Toast";
import { useState } from "react";
import Paginate from "../../Ui/Paginate/Paginate";
import debounce from 'lodash.debounce';
import InputCustom from "../../Ui/input/InputCustom";
import { EyeOutlined } from "@ant-design/icons";
import { getRaincardUsers } from "../../../redux/_actions/cardAction";

let paginationData = '';
function RaincardDetails() {
  const login = useSelector((state) => state?.LoginReducer?.userData);

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
    },
    // {
    //   title: "User Name",
    //   dataIndex: "user_name",
    //   key: "user_name",
    // },
    // {
    //   title: "User Wallet Address",
    //   dataIndex: "wallet_address",
    //   key: "wallet_address",
    // },
    // {
    //   title: "user id",
    //   dataIndex: "user_id",
    //   key: "user_id",
    // },
    {
      title: "User Email",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "Collateral Address",
      dataIndex: "collateral_address",
      key: "collateral_address",
    },
    // {
    //   title: "Phone",
    //   dataIndex: "phoneNo",
    //   key: "phoneNo",
    // },
    {
      title: <span className="center">Action</span>,
      dataIndex: "action",
      key: "action",
    },
  ];
  const limit = 25;
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  // console.log("userData>>>>>>>>>>>>>>>", userData)
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState('');
  const [localValue, setLocalValue] = useState('');

  useEffect(() => {
    console.log('chk search:::::', search)
    fetchRaincardUserList();
  }, [pageCount, search])
  /************************************** fetchCardUserList ****************************************************/
  const fetchRaincardUserList = () => {
    setLoading(true);
    const data = {
      limit: limit,
      page: pageCount,
      search: search
    }
    setTimeout(() => {
      dispatch(getRaincardUsers({ data })).then(response => {
        const res = response.data
        if (res.length > 0) {
          res.map((item, index) => item['key'] = index + 1);
          setUserData(res)
        } else {
          setUserData()
        }
        paginationData = response?.meta?.pages || 0
        setLoading(false)

      }).catch(err => {
        toast.error(err)
        setLoading(false)
      })
    }, 100);
  }
  /************************************** table data ****************************************************/
  const dataSource = userData?.map((item, index) => {
    return (
      {
        key: item.key,
        sno: (<div style={{ marginLeft: 15 }} className="tableIconStyle "><span className="fontFamilyText">{(pageCount - 1) * limit + (index + 1)}</span></div>),
        // user_name: (<div className="tableIconStyle"><span className="fontFamilyText">{item.first_name} {item.last_name}</span></div>),
        email: (<div className="tableIconStyle"><span className="fontFamilyText">{item.email}</span></div>),
        user_id: (<div className="tableIconStyle"> <span className="fontFamilyText"> {item.user_id || 'Null'}  </span> </div>),
        wallet_address: (<div className="tableIconStyle"><span className="fontFamilyText">{item.virtual_address || item.physical_address}</span></div>),
        phoneNo: (<div style={{ textTransform: 'capitalize' }}><span className="fontFamilyText">{item.phone_number}</span></div>),
        collateral_address: (<div className="tableIconStyle"> <span className="fontFamilyText"> {item.collateral_address || '-'}  </span> </div>),
        action: (<Link to={"/RainCard/card-statusRain"} style={{ marginLeft: 35 }} state={{ country: item.country, user_id: item.user_id, showVirtualCard: true, activeTab: '2' }} className="buttonView"><span className="fontFamilyText">View All <EyeOutlined /></span></Link>)
      }
    )
  })
  /************************************** handlePageClick ****************************************************/
  const handlePageClick = (page) => {
    setPageCount(page.selected + 1)
  }
  /**************************************updateSearch****************************************************/
  const updateSearch = (search) => {
    setLocalValue(search);
    debounceData(search)
  };
  /**************************************debounceData****************************************************/
  const debounceData = useCallback(debounce(search => {
    setSearch(search.trim());
    setLocalValue(search.trim());
    setPageCount(1)
  }, 1000),
    [],
  );
  return (
    <>
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item>User Details</Breadcrumb.Item>
        </Breadcrumb>
        <span><b>Assigned Role : </b>{login?.admin_user_access_data ? login?.admin_user_access_data?.role_name : "Super Admin"}</span>
      </div>
      <div className="commonPadding userDatils">
        <div className="annoucements_block_multiData">
          <div style={{ justifyContent: 'end', display: 'flex' }}>
            <InputCustom basicInput placeholder="Search..." maxLength={50} searchImg={<SearchOutlined />} inputSearch value={localValue} onChange={(e) => { updateSearch(e.target.value) }} />
          </div>
        </div>
        <div style={{ marginTop: 15 }}>
          <Table dataSource={dataSource} columns={columns} pagination={false} size="large" />
        </div>
      </div>
      {paginationData > 1 && <Paginate paginationData={paginationData} forcePage={pageCount == '' ? 0 : pageCount - 1} handlePageClick={(page) => handlePageClick(page)} />}
      <Loader isLoading={isLoading} />
    </>
  );
}

export default RaincardDetails;
