import React from "react";
import { Menu, Table, Tabs, Breadcrumb } from "antd";
import { useEffect, useState } from "react";
import Cards from "../../Ui/Cards/Cards";
import "./CardStatus.scss";
import Loader from "../../../Loader/Loader";
import { toast } from "../../Ui/Toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import { useLocation } from "react-router-dom";
import {
    getRainCardDetail,
    RaincardHistory,
} from "../../../redux/_actions/cardAction";
import { decryptCardDetails, toFixedExp } from "../../../Services/Utils";
import moment from "moment";
import { Dropdown } from "antd";
import { BASE_URL, DOWNLOAD_HISTORY, DOWNLOAD_RAIN_CARD_HISTORY } from "../../../Services/EndPoints";
import * as Constants from "../../../constants/constants";
import NodeRSA from "node-rsa";

let active_key = 1;
let trx_type = "";
function CardStatusRain() {
    const login = useSelector((state) => state?.LoginReducer?.userData);

    const columns = [
        {
            title: "S.No.",
            dataIndex: "sno",
            key: "sno",
        },
        {
            title: 'Txn Id',
            dataIndex: "id",
            key: "id",
        },
        // {
        //     title: "Description.",
        //     dataIndex: "description",
        //     key: "description",
        // },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
        },
        // {
        //     title: <span className="center">User ID</span>,
        //     dataIndex: "userId",
        //     key: "userId",
        // },
        {
            title: "Date",
            dataIndex: "postedAt",
            key: "postedAt",
        },
    ];
    const columnss = [
        {
            title: "S.No.",
            dataIndex: "sno",
            key: "sno",
        },
        {
            title: 'Txn Id',
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
        },
        // {
        //     title: <span className="center">User ID</span>,
        //     dataIndex: "userId",
        //     key: "userId",
        // },
        {
            title: "Date",
            dataIndex: "postedAt",
            key: "postedAt",
        },
    ];
    const limit = 25;
    const { TabPane } = Tabs;
    const location = useLocation();
    const dispatch = useDispatch();
    const [pageCount, setPageCount] = useState(1);
    const [isLoading, setLoading] = useState(false);
    const [activeKey, setActiveKey] = useState(location.state?.activeTab || '1');
    const [activeKeyTxn, setActiveKeyTxn] = useState('1');
    const [PhysicalHistory, setPhysicalHistory] = useState();
    const [RainHistory, setRainHistory] = useState();
    const [RainHistoryFee, setRainHistoryFee] = useState();
    const [virtualRainCardData, setVirtualRainCardData] = useState([]);
    console.log("RainHistory>>>>>>...", RainHistory)

    useEffect(() => {
        active_key = 1;
    }, []);

    console.log({ activeKey }, "location.state?.activeTab::", location.state?.user_id, 'country:::::', location.state?.country);
    // console.log("location.state?.activeTab::",location.state?.activeTab);

    /***************************raincardHistory******************* */
    const fetchRainHistory = (trxnType = "", description = '') => {
        console.log('trxnType:::::::', trxnType);

        setLoading(true);
        const data = {
            user_id: location.state?.user_id,
            card_type: "Virtual",
            end_time: moment(new Date()).format("MMYYYY"),
            start_time: moment(new Date()).subtract(5, "months").format("MMYYYY"),
            trnx_type: trxnType,
        };
        setTimeout(() => {
            dispatch(RaincardHistory({ userId: location.state?.user_id, trxnType: trxnType, description: description })).then((response) => {
                const res = response.data;
                console.log("res::1", res, "response::", response);

                if (response.length > 0) {
                    const list = response.sort((a, b) => new Date(b[b.type]?.postedAt) - new Date(a[a.type]?.postedAt));
                    const filteredList = list.filter((item) => item.type?.toLowerCase() != 'payment' && (item['fee']?.description?.toLowerCase() != 'management fee'));
                    const filteredListFee = list.filter((item) => item['fee']?.description?.toLowerCase() == 'management fee');
                    const updatedData = filteredList.map((item) => ({ ...item, key: item.id }));
                    console.log(filteredList, "updatedData::", updatedData);
                    setRainHistoryFee(filteredListFee);
                    setRainHistory(updatedData);
                } else {
                    setRainHistory([]);
                    setRainHistoryFee([]);
                }
                setLoading(false);
            })
                .catch((err) => {
                    toast.error(err);
                    setLoading(false);
                });
        }, 100);
    };
    /*************************************** Raincard************************ */
    const fetchRainCardDetails = () => {
        setLoading(true);

        const data = {
            user_id: location.state?.user_id,
            card_type: "Virtual",
        };

        setTimeout(() => {
            dispatch(getRainCardDetail({ data }))
                .then(async (response) => {
                    if (JSON.stringify(response) !== "{}") {
                        console.log("response::", response);
                        const encryptedPanData = response?.encryptedResult?.encryptedPan;
                        const encryptedCvcData = response?.encryptedResult?.encryptedCvc;
                        const secretKey = response?.encryptedResult?.secretKey;

                        if (encryptedPanData && encryptedCvcData && secretKey) {
                            let cvv_decoded_Data = await decryptCardDetails(encryptedCvcData?.data, encryptedCvcData?.iv, secretKey, 'cvv');
                            let card_decoded_Data = await decryptCardDetails(encryptedPanData?.data, encryptedPanData?.iv, secretKey, 'cardNumber');
                            console.log("decoded_Data>>>>", cvv_decoded_Data, card_decoded_Data);

                            console.log("card_decoded_card fee", response?.cardFee)
                            const virtual_Data = {
                                balance: (response?.balResult?.spendingPower || 0) / 100,
                                card_number: card_decoded_Data || "0000000000000000",
                                card_status: response.result.status || "Inactive",
                                fee_paid: cvv_decoded_Data?.card_apply_fees || "0.00",
                                card_fee: response?.cardFee || 0,
                                maintenance_fee: response?.cardFeeData?.management_fee || 0,
                                loading_fee: response?.cardFeeData?.loading_fee || 0,
                                card_issuance_fee: response?.cardFeeData?.card_issuance_fee || 0,
                                // currency: cvv_decoded_Data?.balance_details?.card_currency || "USD",
                            };

                            setVirtualRainCardData(virtual_Data);
                            fetchRainHistory('');
                        } else {
                            console.error("Missing encrypted data or secretKey in the response.");
                            setVirtualRainCardData({
                                card_number: "****************",
                                card_status: "Inactive",
                            });
                            setLoading(false);
                        }
                    } else {
                        // Handle empty response
                        const virtual_Data = {
                            card_number: "****************",
                            card_status: "Inactive",
                        };
                        setVirtualRainCardData(virtual_Data);
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    console.error("Error fetching Rain Card details:", err);
                    setVirtualRainCardData({
                        card_number: "****************",
                        card_status: "Inactive",
                    });
                    setLoading(false);
                });
        }, 100);
    };

    useEffect(() => {
        fetchRainCardDetails();
        // fetchRainHistory();
    }, [location.state?.user_id]);

    /*****************************************************onPressFilter******************************************************/
    const onPressFilter = (filterType) => {
        trx_type = filterType;
        fetchRainHistory(filterType);
    };
    /*****************************************************downloadCsv******************************************************/
    const download_Rain_Card_History = (type) => {
        const second = new NodeRSA(Constants.KEY);
        second.setOptions({ encryptionScheme: "pkcs1" });
        const enc = second.encrypt(location.state?.user_id, "base64");
        const base64UrlSafeEncodedData = enc
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=/g, "");

        const link = `${BASE_URL}${DOWNLOAD_RAIN_CARD_HISTORY}?userId=${base64UrlSafeEncodedData}&trnxType=&description=${type}&download=true&start_time=${moment(new Date())
            .subtract(5, "months")
            .format("MMYYYY")}&end_time=${moment(new Date()).format("MMYYYY")}`;

        console.log('link::::::', link);
        window.open(link);
        return;
    };
    /************************************** handleTabChange ****************************************************/
    const handleTabChange = (key) => {
        active_key = key;
        setActiveKey(key);
    };
    const handleTabChangeTxn = (key) => {
        active_key = key;
        setActiveKeyTxn(key);
        fetchRainHistory('', key == 1 ? '' : 'management_fee');
    };
    const getTxnTypeRain = (item, type) => {
        return item.type?.toLowerCase() == 'spend' ? type?.merchantName : (item.type?.toLowerCase() == 'collateral' && type?.amount < 0) ? 'Withdraw' : (item.type?.toLowerCase() == 'collateral' && type?.amount > 0) ? 'Top Up' : item.type?.toLowerCase() == 'fee' ? type?.description?.toLowerCase() == 'management fee' ? "Monthly Fee" : type?.description : item?.type
    }
    /************************************raincardhistory******************/
    const Raincard = RainHistory?.map((item, index) => {
        const itemType = item[item.type];
        const time = itemType?.postedAt?.split('T')[0];
        return {
            key: item.id,
            sno: (
                <div style={{ marginLeft: 15 }} className="tableIconStyle ">
                    <span className="fontFamilyText">
                        {(pageCount - 1) * limit + (index + 1)}
                    </span>
                </div>
            ),
            description: (
                <div className="tableIconStyle">
                    <span className="fontFamilyText" style={{ textTransform: 'capitalize' }}>{getTxnTypeRain(item, itemType)}</span>
                </div>
            ),
            id: (
                <div style={{ marginLeft: 0 }} className="tableIconStyle">
                    <span className="fontFamilyText">{item.id}</span>
                </div>
            ),
            userId: (
                <div style={{ marginLeft: 18 }} className="tableIconStyle">
                    <span className="fontFamilyText">{item.fee?.userId}</span>
                </div>
            ),
            postedAt: (
                <div style={{ marginLeft: -20 }} className="tableIconStyle">
                    <span className="fontFamilyText">
                        {moment(time)?.format("DD MMM, YYYY")}
                        {/* |{" "}{moment(time)?.format("h:mm A")} */}
                    </span>
                </div>
            ),
            amount: (
                <div style={{ marginLeft: 18 }} className="tableIconStyle">
                    <span className="fontFamilyText">
                        {" "}
                        {Math.abs(itemType?.amount) / 100}{' '}{itemType?.currency?.toUpperCase() ?? 'USD'}
                    </span>
                </div>
            ),
        };
    });
    /************************************raincardhistoryFee******************/
    const RaincardFee = RainHistoryFee?.map((item, index) => {
        const itemType = item[item.type];
        const time = itemType?.postedAt?.split('T')[0];
        return {
            key: item.id,
            sno: (
                <div style={{ marginLeft: 15 }} className="tableIconStyle ">
                    <span className="fontFamilyText">
                        {(pageCount - 1) * limit + (index + 1)}
                    </span>
                </div>
            ),
            description: (
                <div className="tableIconStyle">
                    <span className="fontFamilyText" style={{ textTransform: 'capitalize' }}>{getTxnTypeRain(item, itemType)}</span>
                </div>
            ),
            id: (
                <div style={{ marginLeft: 0 }} className="tableIconStyle">
                    <span className="fontFamilyText">{item.id}</span>
                </div>
            ),
            userId: (
                <div style={{ marginLeft: 18 }} className="tableIconStyle">
                    <span className="fontFamilyText">{item.fee?.userId}</span>
                </div>
            ),
            postedAt: (
                <div style={{ marginLeft: -20 }} className="tableIconStyle">
                    <span className="fontFamilyText">
                        {moment(time)?.format("DD MMM, YYYY")}
                        {/* |{" "}{moment(time)?.format("h:mm A")} */}
                    </span>
                </div>
            ),
            amount: (
                <div style={{ marginLeft: 18 }} className="tableIconStyle">
                    <span className="fontFamilyText">
                        {" "}
                        ${Math.abs(itemType?.amount) / 100}{' '}{itemType?.currency?.toUpperCase()}
                    </span>
                </div>
            ),
        };
    });
    /************************************** menu ****************************************************/
    const menu = (
        <Menu className="topdropdown">
            <Menu.Item style={{ marginTop: "7px" }}>
                <b> Type of Txn</b>
                <Menu
                    className="trsp_itme"
                    style={{ backgroundColor: "transparent !important" }}
                >
                    <Menu.Item
                        onClick={() => onPressFilter("")}
                        className="subitems"
                        style={{ lineHeight: "30px", height: "30px", listStyle: "none" }}
                    >
                        All
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => onPressFilter("collateral")}
                        className="subitems"
                        style={{ lineHeight: "30px", height: "30px", listStyle: "none" }}
                    >
                        Deposit {/* Withdraw */}
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => onPressFilter("spend")}
                        className="subitems"
                        style={{ lineHeight: "30px", height: "30px", listStyle: "none" }}
                    >
                        Withdraw  {/* Deposit */}
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => onPressFilter("fee")}
                        className="subitems"
                        style={{ lineHeight: "30px", height: "30px", listStyle: "none" }}
                    >
                        Fee Payment {/* Withdraw */}
                    </Menu.Item>
                </Menu>
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <div className="breadcrumb assigned">
                <Breadcrumb>
                    <Breadcrumb.Item>Card Details</Breadcrumb.Item>
                </Breadcrumb>
                <span>
                    <b>Assigned Role : </b>
                    {login?.admin_user_access_data
                        ? login?.admin_user_access_data?.role_name
                        : "Super Admin"}
                </span>
            </div>
            <div className="commonPaddingNew">
                <div className="tabs__body">
                    <Tabs
                        activeKey={activeKey}
                        style={{ marginTop: 15, display: "flex" }}
                        onChange={handleTabChange}
                    // defaultActiveKey="1"
                    >
                        <TabPane tab="Virtual Card" key="2">
                            <div className="commoanSide">
                                <Cards
                                    status={virtualRainCardData?.card_status}
                                    number={virtualRainCardData?.card_number}
                                    feepaid={virtualRainCardData?.card_issuance_fee}
                                    loading_fee={virtualRainCardData?.loading_fee}
                                    maintenance_fee={virtualRainCardData?.maintenance_fee}
                                    isRain
                                    availableBalance={virtualRainCardData?.balance}
                                    cardstatus={virtualRainCardData?.card_status}
                                    country={location.state?.country || ''}
                                />
                            </div>

                            <div className="tabs__body" >
                                <Tabs activeKey={activeKeyTxn} style={{ marginTop: 15, display: 'flex' }} onChange={handleTabChangeTxn} defaultActiveKey="1">
                                    <TabPane tab="TransactionHistory" key="1">
                                        <div className="transec_histry">
                                            <div style={{ marginTop: -5 }} className="transec_histry__heading">
                                                <h2>   </h2>
                                                <div className="addnewSec">
                                                    {RainHistory ? (
                                                        <div className="drop_icon">
                                                            <Dropdown overlay={menu}>
                                                                <button>Filter</button>
                                                            </Dropdown>
                                                        </div>
                                                    ) : null}
                                                    {RainHistory ? (
                                                        <ButtonCustom
                                                            disabled={RainHistory?.length === 0}
                                                            onClick={() => download_Rain_Card_History('')}
                                                            label="Download"
                                                            garyBtn
                                                        />
                                                    ) : null}
                                                </div>
                                            </div>
                                            <Table
                                                dataSource={Raincard}
                                                columns={columnss}
                                                pagination={false}
                                                size="large"
                                            />
                                            <Loader isLoading={isLoading} />
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Monthly Maintenance History" key="2">
                                        <div className="transec_histry">
                                            <div style={{ marginTop: -5 }} className="transec_histry__heading">
                                                <h2>   </h2>
                                                <div className="addnewSec">

                                                    {RainHistoryFee ? (
                                                        <ButtonCustom
                                                            disabled={RainHistoryFee?.length === 0}
                                                            onClick={() => download_Rain_Card_History('management_fee')}
                                                            label="Download"
                                                            garyBtn
                                                        />
                                                    ) : null}
                                                </div>
                                            </div>
                                            <Table
                                                dataSource={RaincardFee}
                                                columns={columns}
                                                pagination={false}
                                                size="large"
                                            />
                                            <Loader isLoading={isLoading} />
                                        </div>
                                    </TabPane>
                                </Tabs>
                            </div>


                            {/* <div className="transec_histry">
                                <div className="transec_histry__heading">
                                    <h2>Transaction history</h2>
                                    <div className="addnewSec">
                                        {RainHistory ? (
                                            <div className="drop_icon">
                                                <Dropdown overlay={menu}>
                                                    <button>Filter</button>
                                                </Dropdown>
                                            </div>
                                        ) : null}
                                        {RainHistory ? (
                                            <ButtonCustom
                                                onClick={() => download_Rain_Card_History()}
                                                label="Download"
                                                garyBtn
                                            />
                                        ) : null}
                                    </div>
                                </div>
                                <Table
                                    dataSource={Raincard}
                                    columns={columnss}
                                    pagination={false}
                                    size="large"
                                />
                                <Loader isLoading={isLoading} />
                            </div> */}

                        </TabPane>

                    </Tabs>
                </div>
            </div>
        </>
    );
}

export default CardStatusRain;
