import React from 'react'
import "./cardcontrol.scss";


function CardDetailsControl(props) {
  return (
    <>
      <div className='carddetail'>
        <div className='add_righttext'><h3><span><img src={props.curencyImg} /></span>{props.Currency}</h3> <h3>{props.liminal}</h3></div>
        <h2 style={{ color: 'black' }}>{props.Balance}</h2>
      </div>
    </>
  )
}

export default CardDetailsControl