export const IS_PRODUCTION = 1;
export const BASE_URL =
  IS_PRODUCTION == 2
    ? "http://172.16.15.120:3002/api/v1/admin/"
    : IS_PRODUCTION == 0
      ? "https://stage-api.triskel-cap.com/api/v1/admin/"
      : "https://api.triskel-cap.com/api/v1/admin/";
export const BASE_IMAGE_URL =
  IS_PRODUCTION == 0
    ? "https://stage-triskel.s3.ap-southeast-1.amazonaws.com"
    : "https://prod-triskel.s3.ap-northeast-2.amazonaws.com";
export const REACT_APP_DOMAIN_KEY = "aeR4EZDBVP28YgGatOzOSXBM76KxJYdg";
// export const BASE_URL = 'http://10.1.8.7:4500/api/v1/admin/'
/**************************************DASHBOARD APIs****************************************************/
export const USERS_COUNT_API = "dashboard";
export const USERS_CHART_API = "user_traffic_chart";
export const CHECK_PERMISSIONS = "subadmin/check_permissions";
export const GET_BALANCE = "balances";
export const GET_REVENUE = "revenue";
export const GET_REVENUE_LIST = "revenue_list";
export const DOWNLOAD_GET_REVENUE_LIST = "download_revenue_list";
/**************************************LOGIN APIs****************************************************/
export const LOGIN_API = "auth/login";
/**************************************CURRENCY APIs****************************************************/
export const GET_CURRENCY_LIST = "fiats/get_currencies";
export const REMOVE_CURRENCY_API = "fiats/remove_currency";
export const ADD_CURRENCY_API = "fiats/add_currency";
export const ADD_CURRENCY_UPDATE_API = "fiats/update_currency";
export const SEARCH_CURRENCY_API = "fiats/search_currency";
/**************************************CUSTOM TOKEN APIs****************************************************/
export const GET_TOKENS_LIST = "token/get_tokens";
export const REMOVE_TOKENS_API = "token/remove_tokens";
export const ADD_TOKENS_API = "token/add_token";
export const SEARCH_TOKENS_API = "token/search_token";
/**************************************USER APIs****************************************************/
export const GET_USER_LIST = "user_list";
export const GET_USER_DATA = "user_data";
export const GET_USER_DETAILS_API = "user_details";
/**************************************LOGOUT API****************************************************/
export const LOGOUT_API = "auth/logout";
/**************************************PASSWORD API****************************************************/
export const CHANGE_PASSWORD_API = "auth/change_password";
/**************************************UPLOAD API****************************************************/
export const FILE_UPLOAD_API = "fiats/file_upload";
/**************************************GECKO APIs****************************************************/
export const GECKO_SYMBOL_URL = "https://api.coingecko.com/api/v3/coins/list";
/**************************************2FA APIs****************************************************/
export const GOOGLE_2FA_STATUS = "auth/google_auth_status";
export const ENABLE_DISABLE_2FA_STATUS = "auth/google_auth_enabledisable";
export const GOOGLE_AUTH_SECRET_KEY = "auth/google_auth_secretkey";
export const GOOGLE_AUTH_VERIFY = "auth/google_2fa_verify";
/**************************************ANNOUNCEMENT API****************************************************/
export const SEARCH_USER_API = "announcements/notification_user_list";
export const PUSH_ANNOUNCEMENT_API = "announcements/allNotifications";
/**************************************FORGOT PASS APIs****************************************************/
export const RESET_PASS_API = "auth/forgot_password";
export const RESET_PASS_ADMIN_API = "subadmin/reset_password";
/**************************************REFERRAL APIs****************************************************/
export const REFERRAL_LIST_API = "referral/getReferralInfo";
/**************************************CARD APIs****************************************************/
export const CARD_USER_LIST_API = "card_module/cards_users";
export const RAIN_CARD_LIST_API = "card_module/raincards_users"
export const USER_SHIPPING_DETAILS_API = "card_module/shipping_detials";
export const USER_CARD_DETAIL_API = "card_module/cards_user_details";
export const USER_RAIN_CARD_DETAIL_API = "card_module/raincards_user_details";
export const USER_CARD_HISTORY_API = "card_module/user_card_history";
export const SUPPORTED_COIN_API = "card_module/card_supported_coins";
export const RAIN_CARD_TRANSECTION_HISTORY = "card_module/issuing/transactions"
export const UPDATE_STATUS_API = "card_module/update_coinstatus";
export const UPDATE_FEE_API = "card_module/update_fees";
export const HYPERCARD_BALANCE_API = "card_module/hc_balnace";
export const LIMINAL_BALANCE_API = "card_module/lmnl_balance";
export const DOWNLOAD_HISTORY = "card_module/card/download_csv";
export const DOWNLOAD_RAIN_CARD_HISTORY = "card_module/issuing/transactionsDownload"


/**************************************CONTACT US APIs****************************************************/
export const QUERY_LIST_API = "contact_us/all_queries";
export const USER_QUERY_LIST = "contact_us/user_query";
export const RESPONSE_QUERY_API = "contact_us/response_to_query";
export const DOWNLOAD_QUERIES_List = "contact_us/contact_us/download_csv";
/************************************ REFERRAL APIs *****************************************************/
export const REFERRAL_TYPE_API = "referral/user_levels";
export const GET_REFERRALS_API = "referral/get_referrals";
export const GET_REFERRAL_DETAIL_API = "referral/get_referral_details";
export const GET_REWARD_HISTORY_API = "referral/reward_history";
export const DOWNLOAD_REWARD_HISTORY = "referral/reward_history/download_csv";
export const UPDATE_USER_LEVEL = "referral/update_user_level";
export const APPROVAL_REQUEST_LIST = "referral/approval_requests";
export const UPDATE_REFERRAL_STATUS = "referral/update_approval_request";
export const GET_CLAIM_REQUEST = "referral/claim_requests";
export const UPDATE_CLAIM_STATUS = "referral/update_claim_status";
/************************************ REQUESTLIST APIs *****************************************************/
export const GET_WEBSITE_REQUEST_LIST = "website_requests/requests_list"
export const GET_NEWSLETTER_LIST = "website_requests/newsLetterListing"
/************************************ Sub Admin APIs *****************************************************/
export const MANU_LIST_API = "subadmin/get_all_menulists";
export const ADD_ROLE_API = "subadmin/add_role";
export const ROLE_LIST_API = "subadmin/all_roles_with_list";
export const DELETE_ROLE = "subadmin/delete_role";
export const EDIT_ROLE_API = "subadmin/edit_role";
export const ROLE_LIST_FOR_DROPDOWN_API = "subadmin/all_roles";
export const ROLE_DETAIL_BY_ROLE_API = "subadmin/role_details";
export const ADD_ADMIN_API = "subadmin/add_new_admin";
export const ADMIN_LIST_API = "subadmin/all_admins";
export const DELETE_ADMIN_API = "subadmin/delete_admin_users";
export const ADMIN_DETAIL_API = "subadmin/admin_access_details";
export const UPDATE_ADMIN_DETAIL_API = "subadmin/update_admin_user_details";
export const RESEND_PASSWORD_API = "subadmin/resend_email";
export const ACTIVITY_LOG_API = "subadmin/activity_logs";
export const ACTIVITY_TYPE_API = "subadmin/get_activity_heads";
/************************************ STO Module *****************************************************/
export const ADD_QUESTION = "question/add";
export const CATEGORY_LIST_API = "category/getCategory";
export const QUESTION_LIST_API = "question/getdropList";
export const QUESTIONS_LIST_API = "question/list";
export const DELETE_QUESTION = "question/delete";
export const UPDATE_QUESTION = "question/update";
export const ADD_CATEGORY = "category/addCategory";
export const CATEGORIES_LIST_API = "category/list";
export const CATEGORIES_DELETE_API = "category/delete";
export const UPDATE_CATEGORY = "category/UpdateCategory";
export const ADD_ASSETS = "asset/addAsset";
export const ASSETS_LIST_API = "asset/list";
export const ASSETS_DELETE_API = "asset/deleteAsset";
export const UPDATE_ASSETS = "asset/updateAsset";
export const ADD_PROPOSAL = "proposal/add_proposal";
export const DELETE_PROPOSAL = "proposal/deleteProposal";

export const UPDATE_PROPOSAL = "proposal/update_proposal";

export const EDIT_PROPOSAL = "proposal/edit_proposal";

// export const UPDATE_AGENT_IN_PROPOSAL =
//   "proposal/update_agent_address_proposal";

export const ASSETS_LIST_DROPDOWN_API = "asset/getdropList";
export const UPLOAD_FILE_DATA_PROPOSAL = "proposal/uploadFileData";
export const PROPOSAL_LIST = "proposal/ProposalList";
export const STO_TRANSACTIONS_LIST = "proposal/sto_transactions_listing";
export const STO_REFUND_TRANSACTIONS_LIST = "proposal/refundTransactionsList";
export const STO_REFUND_AMOUNT = "agent/refundAmountToBuyer";

/**************************** STO AGENT TO ADMIN ****************************/

export const GET_PROPOSAL_LIST = "agent/proposalList";
export const GET_INVESTED_MINTING_LIST =
  "agent/mint_invested_particular_proposal";
export const MINTING_STATUS_UPDATE = "agent/update_approval_for_mint";
export const DOWNLOAD_MINT_PROPOSAL_CSV =
  "agent/mint_invested_admin_download_csv";
export const GET_INVESTED_APP_LIST = "agent/question/getaddressBookListing";

/************************************ OTC APIs *****************************************************/
export const MIN_MAX_API = "otc/get_min_max";
export const CREATE_TRADE_API = "otc/create_trade";
export const GET_OTC_TRANSACTIONS = "otc/otc_trnx";

/************************************ SECONDARY APIs *****************************************************/
export const SECONDARY_PROPOSAL_LIST = "agent/secondaryProposalList";
export const SECONDARY_MARKET_TRANSACTION = "agent/secondaryMarketTransaction";
export const SECONDARY_MARKET_SELLER_DETAILS =
  "agent/secondaryPoposalsOfProposal";

export const SECONDARY_MARKET_UNBLOCK = "agent/cancelRequestRaiseQuoteList";
export const SECONDARY_MARKET_UPDATE_UNBLOCK_STATUS =
  "agent/approveUnblockRequest";
export const SECONDARY_MARKET_UPDATE_TRANSACTION_AFTER_TRANSFER =
  "agent/transferProposalToBuyer";

// export const INVESTED_PARTICULAR_SECONDARY_PROPOSAL =
//   "investedParticularSecondaryProposal";
// export const MINT_PARTICULAR_SECONDARY_PROPOSAL =
//   "mintParticularSeconadryProposalData";
// export const MINT_SECONDARY_CSV = "mintSecondaryDownloadCsvData";
