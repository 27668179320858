import {
  Button,
  Col,
  Input,
  Tabs,
  DatePicker,
  Table,
  Row,
  Breadcrumb,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  getStoRefundTransactionList,
  getStoTransactionList,
  updateTransactionAfterRefund,
} from "../../../redux/_actions/assetAction";
import { useDispatch } from "react-redux";
import { toast } from "../../Ui/Toast/Toast";
import Loader from "../../../Loader/Loader";
import Paginate from "../../Ui/Paginate/Paginate";
import descriptions from "../../Assets/Images/description.svg";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
let paginationData = "";

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
function ViewProposal() {
  const location = useLocation();
  const dispatch = useDispatch();

  const item = location?.state?.proposalData;
  console.log("PROPOSAL DATA", item);

  const [isLoading, setLoading] = useState(false);
  const [transactionsList, setTransactionsList] = useState([]);
  const [refundTransactionsList, setRefundTransactionsList] = useState([]);

  const [activeTab, setActiveTab] = useState("1");
  const [searchInput, setSearchInput] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [metaData, setMetaData] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(10);
  const handleSearchInputChange = (e) => {
    console.log(e.target.value);
    setSearchInput(e.target.value);
  };

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const handleFilterButtonClick = () => {
    fetchTransactionsList();
  };
  console.log("ACTIVETAB", activeTab);
  useEffect(() => {
    if (activeTab === "2") {
      fetchTransactionsList();
    } else if (activeTab === "3") {
      fetchRefundTransactionsList();
    }
  }, [activeTab, searchInput, pageCount]);

  const fetchTransactionsList = async () => {
    const fromDate = dateRange[0]
      ? moment(dateRange[0]).startOf("day").format("YYYY-MM-DD HH:mm:ss")
      : "";
    const toDate = dateRange[1]
      ? moment(dateRange[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss")
      : "";
    console.log("FROM AND TO DATES", fromDate, toDate);
    const data = {
      limit: limit,
      page: pageCount,
      proposal_id: item?.id,
      from_date: fromDate,
      to_date: toDate,
      search: searchInput,
    };
    setTimeout(() => {
      dispatch(getStoTransactionList({ data }))
        .then((response) => {
          const res = response.data;
          console.log("response", response);

          console.log("RESSSS", res);
          setTransactionsList(res);
          setMetaData(response?.meta);
          setTotalPages(response?.meta?.total);
          paginationData = response?.data?.meta?.pages || 0;

          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
        });
    }, 100);
  };

  const fetchRefundTransactionsList = async () => {
    const fromDate = dateRange[0]
      ? moment(dateRange[0]).startOf("day").format("YYYY-MM-DD HH:mm:ss")
      : "";
    const toDate = dateRange[1]
      ? moment(dateRange[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss")
      : "";
    console.log("FROM AND TO DATES", fromDate, toDate);
    const data = {
      limit: limit,
      page: pageCount,
      proposal_id: item?.id,
      from_date: fromDate,
      to_date: toDate,
      search: searchInput,
    };
    setTimeout(() => {
      dispatch(getStoRefundTransactionList({ data }))
        .then((response) => {
          const res = response.data;
          console.log("response", response);

          console.log("RESSSS", res);
          setRefundTransactionsList(res);
          setMetaData(response?.meta);
          setTotalPages(response?.meta?.total);
          paginationData = response?.data?.meta?.pages || 0;

          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
        });
    }, 100);
  };

  const handleRefund = (record) => {
    // Your refund handling logic here
    // "destination_address":"0x54D9949696fdd52421A8EE7F0c2222d008674128",
    // "amount":10,
    // "proposal_id":1,
    // "transaction_id":1

    console.log("Refunding:", record);
    const data = {
      destination_address: record?.user_adrs,
      amount: record?.Refund_amount,
      proposal_id: record?.proposal_id,
      transaction_id: record?.id,
    };
    console.log("data for refund", data);
    setTimeout(() => {
      setLoading(true);

      dispatch(updateTransactionAfterRefund({ data }))
        .then((response) => {
          fetchRefundTransactionsList();
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    }, 100);
  };

  const handlePageClick = (page) => {
    setPageCount(page.selected + 1);
  };

  // Define columns without the Action column
  let columns = [
    {
      title: "User name",
      dataIndex: "username",
      key: "username",
      render: (text, record) => ` ${record?.wallet_data?.username}`,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, record) => ` ${record?.wallet_data.email}`,
    },
    {
      title: "Proposal name",
      dataIndex: "proposal_name",
      key: "proposal_name",
      render: (text, record) => ` ${record?.proposal_data?.Proposal_name}`,
    },
    {
      title: "Invested amount(TUT)",
      dataIndex: "invested_amount",
      key: "Invested_amount",
      render: (text, record) => ` ${record?.Invested_amount}`,
    },
    {
      title: "Investment date",
      dataIndex: "investment_date",
      key: "investment_date",
      render: (text, record) =>
        `${moment(record?.Investment_date).format("DD-MM-YYYY HH:mm")}`,
    },
    {
      title: "Released security token amount",
      dataIndex: "released_amount",
      key: "Released_security_token_amount",
      render: (text, record) =>
        `${
          record?.Released_security_token_amount !== null
            ? record?.Released_security_token_amount
            : 0
        } ${item?.symbol}`,
    },
    {
      title: "Status ",
      dataIndex: "status",
      key: "status",
      render: (text, record) =>
        `${
          record?.token_mint_status === "complete"
            ? "Minted"
            : record?.refund_status !== null
            ? record?.refund_status
            : "Not minted"
        }`,
    },
    {
      title: "Refund amount",
      dataIndex: "refund_amount",
      key: "refund_amount",
      render: (text, record) =>
        activeTab === "3"
          ? `${record?.Refund_amount}`
          : activeTab === "2" && record?.refund_status === "complete"
          ? record?.Refund_amount
          : 0,
    },
  ];

  // Add the Action column conditionally
  if (activeTab === "3") {
    columns.push({
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <button onClick={() => handleRefund(record)}>Refund</button>
      ),
    });
  }

  const items = [
    {
      icon: descriptions,
      text: "Company name :",
      description: <p>{item?.company_name}</p>,
    },
    {
      icon: descriptions,
      text: "Token name :",
      description: <p>{item?.token_name}</p>,
    },
    {
      icon: descriptions,
      text: "Token quantity :",
      description: <p>{item?.token_quantity}</p>,
    },
    {
      icon: descriptions,
      text: "Min Investment :",
      description: <p>{item?.min_investment}</p>,
    },
    {
      icon: descriptions,
      text: "Raised fund  :",
      description: <p>{item?.raise_fund}</p>,
    },
    {
      icon: descriptions,
      text: "Token Minted :",
      description: <p>{item?.minted_token_quantity}</p>,
    },
    {
      icon: descriptions,
      text: " Fee  :",
      description: <p>{item?.fee}</p>,
    },
    {
      icon: descriptions,
      text: "Project Yield :",
      description: (
        <p>{item?.project_yield === "" ? 0 : item?.project_yield}</p>
      ),
    },
    {
      icon: descriptions,
      text: "Start Date :",
      description: <p>{moment(item?.start_date).format("DD-MM-YYYY HH:mm")}</p>,
    },
    {
      icon: descriptions,
      text: "End Date:",
      description: <p>{moment(item?.end_date).format("DD-MM-YYYY HH:mm")}</p>,
    },
  ];
  return (
    <>
      <div className="">
        <div className="breadcrumb assigned">
          <Breadcrumb>
            <Breadcrumb.Item>{item?.title}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="commonPadding">
          <div
            className="stageImage"
            style={{
              width: "100%", // Fixed container width
              height: "100%", // Fixed container height
              overflowX: "auto",
              whiteSpace: "nowrap",
            }}
          >
            {item?.proposal_file.length > 0 && (
              <div style={{ display: "flex" }}>
                {item.proposal_file.map((file, index) => (
                  <div
                    key={index}
                    style={{ marginRight: "10px", flex: "0 0 auto" }}
                  >
                    <img
                      src={file.url}
                      alt={`Proposal Image ${index + 1}`}
                      style={{
                        width: "100%",
                        maxWidth: 260,
                        maxHeight: 274, // Take up full width of container
                        height: "100%", // Take up full height of container
                        display: "block",
                        objectFit: "cover", // Maintain aspect ratio and fill container
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="tabs__body ">
            <Tabs
              style={{ marginTop: 15, display: "flex" }}
              onChange={(e) => {
                setActiveTab(e);
              }}
              defaultActiveKey="1"
            >
              <TabPane tab="Overview" key="1">
                <div className="mb-2 descriptCard ">
                  <div className="descriptCard_head">
                    <img src={descriptions} alt="" className="disHead" />
                    <h6>Description :</h6>
                  </div>
                  <p className="descriptCard_paragraph">
                    {stripHtmlTags(item?.description)}
                  </p>
                </div>
                <div className="detailsSec viewSec">
                  {/* <h4>Proposal details</h4> */}
                  {items.map((item, index) => (
                    <div key={index} className="borderItem ">
                      <img src={item?.icon} alt="" />
                      <div>
                        <p className="font-weight"> {item?.text} </p>
                        <p>{item?.description}</p>
                      </div>
                    </div>
                  ))}
                  {/* <div className="dFlexAlc borderItem">
                  <p>Company name</p>
                  <p>{item?.company_name}</p>
                </div>
                <div className="dFlexAlc borderItem">
                  <p>Token name</p>
                  <p>{item?.token_name}</p>
                </div>
                <div className="dFlexAlc borderItem">
                  <p>Token Symbol</p>
                  <p>{item?.symbol}</p>
                </div>
                <div className="dFlexAlc borderItem">
                  <p>Token quantity</p>
                  <p>{item?.token_quantity}</p>
                </div>
                <div className="dFlexAlc borderItem">
                  <p>Min Investment</p>
                  <p>{item?.min_investment}</p>
                </div>
                <div className="dFlexAlc borderItem">
                  <p>Raised fund </p>
                  <p>{item?.raise_fund}</p>
                </div>
                <div className="dFlexAlc borderItem">
                  <p>Token Minted</p>
                  <p>{item?.minted_token_quantity}</p>
                </div>
                <div className="dFlexAlc borderItem">
                  <p>Fee </p>
                  <p>{item?.fee}</p>
                </div>
                <div className="dFlexAlc borderItem">
                  <p>Project Yield</p>
                  <p>{item?.project_yield}</p>
                </div>
                <div className="dFlexAlc borderItem">
                  <p>Start Date</p>
                  <p>
                    {moment.utc(item?.start_date).format("DD-MM-YYYY HH:mm")}
                  </p>
                </div>
                <div className="dFlexAlc borderItem">
                  <p>End Date</p>
                  <p>{moment.utc(item?.end_date).format("DD-MM-YYYY HH:mm")}</p>
                </div> */}
                </div>
              </TabPane>
              <TabPane tab="Transactions List" key="2">
                <div className="transactionItems">
                  <div className="transactions-list-filters">
                    <Input
                      placeholder="Search transactions"
                      value={searchInput}
                      onChange={handleSearchInputChange}
                      style={{ width: 200, marginRight: 10 }}
                    />
                    <RangePicker
                      onChange={handleDateRangeChange}
                      style={{ marginRight: 10 }}
                    />
                    <Button type="primary" onClick={handleFilterButtonClick}>
                      Filter
                    </Button>
                  </div>
                  <br></br>
                  <div className="transactions-list">
                    <Table
                      dataSource={transactionsList}
                      columns={columns}
                      // pagination={{
                      //   pageSize: limit,
                      //   total: totalPages,
                      //   onChange: (page) => {
                      //     setPageCount(page);
                      //   },
                      // }}
                      pagination={false}
                    />
                  </div>
                </div>
              </TabPane>
              <TabPane tab="Refund Transactions" key="3">
                <div className="transactionItems">
                  <div className="transactions-list-filters">
                    <Input
                      placeholder="Search transactions"
                      value={searchInput}
                      onChange={handleSearchInputChange}
                      style={{ width: 200, marginRight: 10 }}
                    />
                    <RangePicker
                      onChange={handleDateRangeChange}
                      style={{ marginRight: 10 }}
                    />
                    <Button type="primary" onClick={handleFilterButtonClick}>
                      Filter
                    </Button>
                  </div>
                  <br></br>
                  <div className="transactions-list">
                    <Table
                      dataSource={refundTransactionsList}
                      columns={columns}
                      // pagination={{
                      //   pageSize: limit,
                      //   total: totalPages,
                      //   onChange: (page) => {
                      //     setPageCount(page);
                      //   },
                      // }}
                      pagination={false}
                    />
                  </div>
                </div>
              </TabPane>
            </Tabs>
          </div>
          {paginationData > 1 && (
            <Paginate
              paginationData={paginationData}
              forcePage={pageCount == "" ? 0 : pageCount - 1}
              handlePageClick={(page) => handlePageClick(page)}
            />
          )}
        </div>
        <Loader isLoading={isLoading} />
      </div>
    </>
  );
}

export default ViewProposal;
