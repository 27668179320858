import "./App.scss";
import { Routes, Route, useNavigate } from "react-router-dom";
import Mainlayout from "./Components/Layout/Mainlayout/Mainlayout";
import Login from "./Components/Pages/Login/Login";
import DashboardLayout from "./Components/Layout/DashboardLayout/DashboardLayout";
import PageNotFound from "./Components/Pages/PageNotFound/PageNotFound";
import { useEffect, useState } from "react";
import Dashboard from "./Components/Pages/Dashboard/Dashboard";
import UserDetails from "./Components/Pages/UserDetails/UserDetails";
import UserActivity from "./Components/Pages/UserDetails/UserActivity";
import AppCustomisation from "./Components/Pages/AppCustomisation/AppCustomisation";
import Annoucements from "./Components/Pages/Annoucements/Annoucements";
import AdminControls from "./Components/Pages/AdminControls/AdminControls";
import {
  clearUserCookie,
  deleteAllCookies,
  getData,
  saveData,
} from "./Services/Utils";
import * as Constants from "../src/constants/constants";
import ForgotPassword from "./Components/Pages/ForgotPassword/ForgotPassword";
import ReferralReward from "./Components/Pages/Referral&Reward/ReferralReward";
import { useIdleTimer } from "react-idle-timer";
import { toast } from "./Components/Ui/Toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import { logoutApp } from "./redux/_actions";
import CardStatus from "./Components/Pages/CardStatus/CardStatus.jsx";
import CardDetails from "./Components/Pages/CardDetails/CardDetails.jsx";
import CardControl from "./Components/Pages/CardControl/CardControl";
import PhysicalCardIssuance from "./Components/Pages/PhysicalCardIssuance/PhysicalCardIssuance";
import ContactUs from "./Components/Pages/ContactUs/ContactUs";
import View from "./Components/Pages/ContactUs/View";
import Referrals from "./Components/Pages/Referrals/Referrals.js";
import ReferralDetails from "./Components/Pages/Referrals/ReferralDetails.js";
import MasterFranchise from "./Components/Pages/Referrals/MasterFranchise.js";
import ApprovalRecquest from "./Components/Pages/ApprovalRecquest/ApprovalRecquest.js";
import RewardManagement from "./Components/Pages/RewardManagement/RewardManagement.js";
import { REACT_APP_DOMAIN_KEY } from "./Services/EndPoints";
import SubAdmin from "./Components/Pages/SubAdmin/SubAdmin.jsx";
import ResetPassword from "./Components/Pages/ResetPassword/ResetPassword";
import { checkPermissions } from "./redux/_actions/dashboardAction";
import CryptoPortfolio from "./Components/Pages/CryptoPortfolio /CryptoPortfolio.jsx";
import Questionnaire from "./Components/Pages/Questionnaire/Questionnaire";
import AssetIssuance from "./Components/Pages/AssetIssuance/AssetIssuance";
import AssetsName from "./Components/Pages/AssetsName/Assets";
import Proposal from "./Components/Pages/AssetIssuance/Proposal/Proposal.jsx";
import AddressWhitelist from "./Components/Pages/AddressWhitelist/AddressWhitelist.js";
import WhiteListAddresses from "./Components/Pages/AddressWhitelist/AddressWhitelist.js";
import WhiteListDetail from "./Components/Pages/AddressWhitelist/WhitelistDetail";
import EditProposalPage from "./Components/Pages/AssetIssuance/Proposal/EditProposal";
import ViewProposal from "./Components/Pages/AssetIssuance/ViewProposal";
import Raincard from "./Components/Pages/RaincardDetails/RaincardDetails.jsx";

import OtcControls from "./Components/Pages/OtcControls/OtcControls.jsx";
import OtcTransactions from "./Components/Pages/OtcTransactions/OtcTransactions";
import MintingAddresses from "./Components/Pages/MintingAddresses/MintingAddresses";
import MintingDetail from "./Components/Pages/MintingAddresses/MintingDetail";
import SecondaryProposals from "./Components/Pages/SecondaryProposals/SecondaryProposals";
import SecondaryProposalSellerDetails from "./Components/Pages/SecondaryProposals/SecondaryProposalSellerDetails";
import SecondaryTransactionDetails from "./Components/Pages/SecondaryProposals/SecondaryTransactionDetails";
import UnblockRequest from "./Components/Pages/UnblockRequest/UnblockRequest.jsx";
import WebsiteRequest from "./Components/Pages/WebsiteRequest/WebsiteRequest";
import NewsletterList from "./Components/Pages/NewsletterList/NewsletterList";
import CardStatusRain from "./Components/Pages/CardStatus/CardStatusRain.jsx";
function App() {
  const dispatch = useDispatch();
  const login = useSelector((state) => state?.LoginReducer?.userData);
  // let tab_data = login?.admin_user_access_data?.access ? JSON.parse(login?.admin_user_access_data?.access) : "";
  // let role_name = login?.admin_user_access_data ? login?.admin_user_access_data?.role_name : "super_admin";
  // console.log("chk login11111:::::::", login);

  const onIdle = () => {
    logout();
  };
  /************************************** confirm Logout ****************************************************/
  const logout = async () => {
    const theme = await getData(Constants.APP_THEME);
    let urlArray = window.location.href.split("/");
    if (urlArray[urlArray.length - 1] === "new_admin") {
      toast.error("Session Expired");
    }
    clearUserCookie("csrfToken", "", 0);
    localStorage.removeItem("_barong_session");
    sessionStorage.clear();
    localStorage.clear();
    deleteAllCookies();
    saveData(Constants.APP_THEME, theme);
    // dispatch(logoutApp({}));
    //navigate("/");
    window.location.replace("/" + REACT_APP_DOMAIN_KEY);
  };

  const logout_on_deleted = async () => {
    clearUserCookie("csrfToken", "", 0);
    localStorage.removeItem("_barong_session");
    sessionStorage.clear();
    localStorage.clear();
    deleteAllCookies();
    window.location.replace("/" + REACT_APP_DOMAIN_KEY);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 10,
    promptBeforeIdle: 1000 * 30,
  });
  useEffect(() => {
    const themes = getData(Constants.APP_THEME);
    // console.log("themes::::::", themes);
    themes == "darkTheme"
      ? document.body.classList.add("darkTheme")
      : document.body.classList.add("lightTheme");
    let urlArray = window.location.href.split("/");
    if (urlArray[urlArray.length - 1] === "new_admin") {
      logout();
    }
    // console.log("login", login);
    if (login) {
      checkAuthorization();
    }
  }, []);
  const checkAuthorization = () => {
    setTimeout(() => {
      dispatch(checkPermissions({}))
        .then((res) => {
          console.log("checkAuthorization", res);
        })
        .catch((err) => {
          if ("Unable to decode data.") {
            toast.error("User does not exist");
            logout_on_deleted();
          } else {
            toast.error(err);
          }
        });
    }, 100);
  };
  return (
    <Routes>
      {login ? (
        <Route path="/" element={<DashboardLayout />}>
          <Route index path="dashboard" element={<Dashboard />} />
          <Route index path="/" element={<Dashboard />} />
          <Route index path="userDetails" element={<UserDetails />} />
          <Route
            index
            path="userDetails/userActivity"
            element={<UserActivity />}
          />
          <Route index path="edit-proposal" element={<EditProposalPage />} />
          <Route index path="view-proposal" element={<ViewProposal />} />
          <Route index path="RainCard" element={<Raincard />} />
          <Route
            index
            path="RainCard/card-statusRain"
            element={<CardStatusRain />}
          />
          <Route index path="appCustomisation" element={<AppCustomisation />} />
          <Route index path="announcements" element={<Annoucements />} />
          <Route index path="cardcontrol" element={<CardControl />} />
          <Route index path="contactUs" element={<ContactUs />} />
          <Route index path="contactUs/view" element={<View />} />
          <Route index path="card-details" element={<CardDetails />} />
          <Route
            index
            path="card-issuance"
            element={<PhysicalCardIssuance />}
          />
          <Route
            index
            path="card-details/card-status"
            element={<CardStatus />}
          />
          <Route index path="adminControls" element={<AdminControls />} />
          <Route index path="referralReward" element={<ReferralReward />} />
          <Route index path="referrals" element={<Referrals />} />
          <Route index path="referralDetails" element={<ReferralDetails />} />
          <Route index path="masterFranchise" element={<MasterFranchise />} />
          <Route index path="approvalRecquest" element={<ApprovalRecquest />} />
          <Route index path="rewardManagement" element={<RewardManagement />} />
          <Route index path="sub-admin" element={<SubAdmin />} />
          <Route index path="CryptoPortfolio" element={<CryptoPortfolio />} />
          <Route index path="assetIssuance" element={<AssetIssuance />} />
          <Route index path="questionnaire" element={<Questionnaire />} />
          <Route index path="assets" element={<AssetsName />} />
          <Route index path="Proposal" element={<Proposal />} />
          {/* <Route
            index
            path="whiteListAddresses"
            element={<WhiteListAddresses />}
          />
          <Route
            index
            path="whiteListAddresses/whiteListDetail"
            element={<WhiteListDetail />}
          /> */}

          <Route index path="OtcControls" element={<OtcControls />} />
          <Route index path="OtcTransactions" element={<OtcTransactions />} />
          <Route
            index
            path="/mintingAddresses"
            element={<MintingAddresses />}
          />
          <Route
            index
            path="mintingAddresses/mintingDetails"
            element={<MintingDetail />}
          />
          <Route
            index
            path="secondaryProposals"
            element={<SecondaryProposals />}
          />

          <Route
            index
            path="secondaryProposals/sellerDetails"
            element={<SecondaryProposalSellerDetails />}
          />
          <Route
            index
            path="secondaryTransactions/details"
            element={<SecondaryTransactionDetails />}
          />
          <Route index path="/unblockRequest" element={<UnblockRequest />} />
          <Route index path="/websiteRequest" element={<WebsiteRequest />} />
          <Route index path="/newsletterList" element={<NewsletterList />} />



        </Route>
      ) : (
        <Route path="/" element={<Mainlayout />}>
          <Route index path="/" element={<Login />} />
          <Route path="forgotpassword" element={<ForgotPassword />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="resetpassword" element={<ResetPassword />} />
          <Route index path="/new_admin" element={<Login />} />
        </Route>
      )}
    </Routes>
  );
}

export default App;
